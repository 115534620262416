import styled from 'styled-components';

export const Container = styled.footer`
  background-color: #151b28;
  padding: 32px 16px;
  h2 {
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: #fff;
  }

  @media only screen and (min-width: 600px) {
    padding: 40px 32px;
    img {
      width: 142px;
    }
  }
  @media only screen and (min-width: 1024px) {
    padding: 64px 80px;
    img {
      width: 163 px;
    }
  }
`;

export const TextBox = styled.div`
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
    color: #f9f9f9;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #a5acba;
    margin-bottom: 8px;
  }
  .blueColor {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #437ef7;
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;

export const LinkBox = styled.div`
  border-bottom: 1px solid #2e3545;
  padding: 32px 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #a5acba;
    :visited {
      color: #a5acba;
    }
  }

  @media only screen and (min-width: 600px) {
    padding: 40px 0 32px;
    gap: 24px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 64px 0 32px;
    gap: 48px;
  }
`;

export const CopyR = styled.p`
  display: block;
  padding: 24px 0 32px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #a5acba;
`;
