/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useParamsState } from '../../Providers/ParamsState';
import LogoDefault from '../../assets/logoMartinelli.png';
import * as S from './style';

export const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [classNameLogo, setClassNameLogo] = useState(false);
  const [mobileMenu, SetMobileMenu] = useState(false);
  const [media] = useState('(max-width:1023px)');
  const [idParams, setIdParams] = useState(null);

  const { isLoading, urlDownloads } = useParamsState();

  useEffect(() => {
    setIdParams(localStorage.getItem('idParams'));
  }, [localStorage.getItem('idParams')]);

  useEffect(() => {
    if (idParams !== null) {
      setClassNameLogo(true);
    } else {
      setClassNameLogo(false);
    }
  }, [idParams]);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(media);
    const changeMatch = () => {
      SetMobileMenu(mediaQueryList.matches);
      setOpenMenu(false);
    };

    changeMatch();

    mediaQueryList.addEventListener('change', changeMatch);
    return () => {
      mediaQueryList.removeEventListener('change', changeMatch);
    };
  }, [media]);

  if (!isLoading) {
    return (
      <S.Container>
        {openMenu && <S.CloseMenu onClick={() => setOpenMenu(false)} />}
        <S.SubContainer>
          <Link
            to={`/${idParams}`}
            className={classNameLogo ? 'logo' : 'logoDefault'}
          >
            <img
              src={
                localStorage.getItem('logo')  !== 'undefined'
                  ? localStorage.getItem('logo')
                  : LogoDefault
              }
              alt="logo"
            />
          </Link>
          {!mobileMenu && (
            <S.MenuBoxDesktop>
              <div>
                <HashLink to={`/${idParams}#consulta`}>Consultar</HashLink>
                <a href="/formularioDeReclamacao">Denunciar</a>
                {urlDownloads && (
                  <HashLink to={`/${idParams}#leiturasImportantes`}>
                    Documentos
                  </HashLink>
                )}
              </div>
              <div>
                <a href="#ajuda">
                  Ajuda <HelpOutlineIcon />
                </a>
                <a
                  // href="https://calm-island-021485c0f.3.azurestaticapps.net"
                  href={`${process.env.REACT_APP_ADMIN_URL}`}
                  target="_blank"
                >
                  Administrativo <LockOutlinedIcon />
                </a>
              </div>
            </S.MenuBoxDesktop>
          )}
          {mobileMenu && (
            <button onClick={() => setOpenMenu(!openMenu)}>
              {openMenu ? <CloseIcon /> : <MenuIcon />}
            </button>
          )}
        </S.SubContainer>
        {media && (
          <S.MenuBox open={openMenu}>
            <div>
              <HashLink to={`/${idParams}#consulta`}>Consultar</HashLink>
              <a href="/formularioDeReclamacao">Denunciar</a>
              {urlDownloads && (
                <HashLink to={`/${idParams}#leiturasImportantes`}>
                  Documentos
                </HashLink>
              )}
            </div>
            <div>
              <a href="#ajuda">
                Ajuda <HelpOutlineIcon />
              </a>
              <a
                // href="https://calm-island-021485c0f.3.azurestaticapps.net"
                href={`${process.env.REACT_APP_ADMIN_URL}`}
                target="_blank"
              >
                Administrativo <LockOutlinedIcon />
              </a>
            </div>
          </S.MenuBox>
        )}
      </S.Container>
    );
  }
};
