import { Api } from '../Api';

export const handleGetData = async (url) => {
  const response = await Api.get(url, {
    headers: {
      accept: 'text/plain',
    },
  });

  return response;
};

export const handleGetConsultation = async (url) => {
  const response = await Api.get(url, {
    headers: {
      accept: 'text/plain',
    },
  });

  return response;
};

export const handleGetQuestions = async (url) => {
  const response = await Api.get(url, {
    headers: {
      accept: 'text/plain',
    },
  });

  return response;
};

export const handlePostResponse = async (url, data) => {
  const response = await Api.post(url, data, {
    headers: {
      accept: 'text/plain',
    },
  });

  return response;
};

export const handlePostUrlFile = async (url, data) => {
  const response = await Api.post(url, data, {
    headers: {
      accept: 'text/plain',
      'Content-Type': 'multipart/form-data',
    },
  });

  return response;
};
