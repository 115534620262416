import styled from 'styled-components';

export const Container = styled.section`
  padding: 72px 16px 64px;
  @media only screen and (min-width: 600px) {
    padding: 72px 48px 64px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 72px 80px 64px;
  }
`;

export const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  background-color: #f8f9fb;
  border-radius: 10px;
  @media only screen and (min-width: 600px) {
    padding: 32px;
  }
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    gap: 80px;
  }
`;

export const TextBox = styled.div`
  @media only screen and (min-width: 1024px) {
    width: 632px;
  }

  span {
    color: ${({ color }) => color.primaria};
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 8px;
    @media only screen and (min-width: 600px) {
      font-size: 14px;
    }
  }
  h1 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
    @media only screen and (min-width: 600px) {
      font-size: 32px;
      margin-bottom: 32px;
    }
    @media only screen and (min-width: 1024px) {
      margin: 0;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  @media only screen and (min-width: 600px) {
    gap: 0;
    flex-direction: row;
  }
  @media only screen and (min-width: 1024px) {
    width: 440px;
  }

  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
    @media only screen and (min-width: 600px) {
      border-radius: 6px 0 0 6px;
      width: 100%;
    }
  }
  button {
    color: #fff;
    background: ${({ color }) => color.primaria};
    width: 100%;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
    border-radius: 5px;
    padding: 8px 12px;
    border: 1px solid ${({ color }) => color.primaria};
    height: 40px;
    @media only screen and (min-width: 600px) {
      border-radius: 0 6px 6px 0;
      padding: 12px 18px;
      width: fit-content;
    }
  }
`;
