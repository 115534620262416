import { FormControlLabel } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.section`
  padding: 16px 0;
  input,
  label[data-shrink='false']
    + .MuiInputBase-formControl
    .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
  textArea {
    background-color: #fff;
  }
  .css-z1o969-MuiInputBase-root-MuiOutlinedInput-root {
    background-color: #fff;
  }
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    background-color: #fff;
  }
`;

export const TextBox = styled.div`
  h1 {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.02em;
    margin-bottom: 8px;
    @media only screen and (min-width: 600px) {
      font-size: 32px;
    }
  }
  p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.1px;
    color: #5f6d7e;
  }
`;

export const FormBox = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;

  button {
    color: #fff;
    background-color: ${({ color }) => color.primaria};
    width: 100%;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
    border-radius: 5px;
    padding: 8px 12px;
    border: 1px solid ${({ color }) => color.primaria};
    height: 40px;
    @media only screen and (min-width: 600px) {
      padding: 12px 18px;
      width: fit-content;
    }
  }
  span {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.1px;
    color: #5f6d7e;
    margin-top: 8px;
    display: block;
  }

  .buttonSubmit {
    :disabled {
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;
    }
  }

  .nameEmail {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media only screen and (min-width: 600px) {
      flex-direction: row;
      gap: 24px;
    }
    @media only screen and (min-width: 1024px) {
      gap: 32px;
    }
  }
`;

export const formCardFile = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 600px) {
    gap: 0;
    flex-direction: row;
  }
  @media only screen and (min-width: 1024px) {
    width: 440px;
  }

  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
    background-color: #fff;
    border-radius: 6px 0 0 6px;
    height: 100%;
  }
  button {
    color: #fff;
    background-color: ${({ color }) => color.primaria};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
    border: 1px solid ${({ color }) => color.primaria};
    height: 100%;
    border-radius: 0 6px 6px 0;
    padding: 12px 18px;
    width: fit-content;
  }
`;

export const FormCard = styled.div`
  border-radius: 8px;
  padding: 16px;

  h3 {
    margin-bottom: 16px;
  }
  p {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.1px;
    color: #49556d;
    margin-top: -16px;
    margin-bottom: 16px;
    span {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.1px;
      font-weight: 600;
      color: #b02817;
      margin: 16px 0;
      display: block;
    }
  }
`;

export const FormControlLabelCustom = styled(FormControlLabel)`
  small {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #272d37;
    display: block;
    margin-top: -4px;
    a {
      color: #6495f7;
    }
  }
`;
