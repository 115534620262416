import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Link, useNavigate } from 'react-router-dom';
import {
  handleGetData,
  handleGetQuestions,
  handlePostResponse,
  handlePostUrlFile,
} from '../../Services';
import { useParamsState } from '../../Providers/ParamsState';
import * as S from './style';

export const Form = () => {
  const [question, setQuestion] = useState();
  const [selectedFileName] = useState('');
  const [corporacaoId] = useState(localStorage.getItem('idCorporacao'));
  const [scienceTerms, setScienceTerms] = useState(true);
  const [anonymous, setAnonymous] = useState(false);
  const [file, setFile] = useState(null);
  const [loading] = useState(false);
  const [nomeComercial] = useState(localStorage.getItem('idParams'));
  const [optionsBanch, setOptionsBanch] = useState([]);
  const [idParamsLess, setdIparamsLess] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: 'Error na hora de enviar respostas',
  });
  const [anexdoFile, setAnexoFile] = useState([]);
  const [fileAdd, setFileAdd] = useState([]);

  const { isLoading, colors } = useParamsState();

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    reset,
    register,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    handleGetQuestions('corporacao/formulario')
      .then((response) => {
        setQuestion(response?.data?.perguntas);
      })
      .catch((e) => console.log('error => ', e));
  }, []);

  useEffect(() => {
    setFile(watch('file'));
  }, [watch('file')]);

  useEffect(() => {
    if (file) {
      handleFiles();
    }
  }, [file]);

  const handleFiles = () => {
    const formData = new FormData();
    formData.append('file', file[0]);
    formData.append('fileName', file[0]?.name);
    if (file[0]?.name) {
      setFileAdd([...fileAdd, file[0]?.name]);
    }
    handlePostUrlFile(
      `storage?corporacao=${corporacaoId}&fileName=${file[0]?.name}&contentType=${file[0]?.type}`,
      formData,
    )
      .then((response) => {
        // setFileUrl(response?.data);
        setAnexoFile([
          ...anexdoFile,
          {
            observacao: '',
            nome: file[0]?.name,
            url: response?.data.toString(),
          },
        ]);
      })
      .catch((e) => console.log('error => ', e));
  };

  useEffect(() => {
    handleGetData(`corporacao/${nomeComercial}`)
      .then((response) => {
        setOptionsBanch(response?.data?.configuracao?.filiais?.split(','));
      })
      .catch((e) => {
        console.log('Error => ', e);
      });
  }, [nomeComercial]);

  const handleSubmitPost = (data) => {
    if (nomeComercial !== null) {
      handlePostResponse('denuncia', {
        corporacaoId: corporacaoId,
        remetente: data?.name,
        remetenteEmail: data?.email,
        filial: data?.branch,
        perguntas: [
          {
            nome: question[0]?.nome,
            formato: question[0]?.formato,
            respostas: data?.collaborator
              ? [
                  {
                    nome: data?.collaborator,
                  },
                ]
              : [],
          },
          {
            nome: question[1]?.nome,
            formato: question[1]?.formato,
            respostas: data?.typeComplaint
              ? [
                  {
                    nome: data?.typeComplaint,
                  },
                ]
              : [],
          },
          {
            nome: question[3]?.nome,
            formato: question[3]?.formato,
            respostas: data?.sector
              ? [
                  {
                    nome: data?.sector,
                  },
                ]
              : [],
          },
          {
            nome: question[4]?.nome,
            formato: question[4]?.formato,
            respostas: data?.fact
              ? [
                  {
                    nome: data?.fact,
                  },
                ]
              : [],
          },
          {
            nome: question[5]?.nome,
            formato: question[5]?.formato,
            respostas: data?.aware
              ? [
                  {
                    nome: data?.aware,
                  },
                ]
              : [],
          },
          {
            nome: question[6]?.nome,
            formato: question[6]?.formato,
            respostas: data?.peopleInvolved
              ? [
                  {
                    nome: data?.peopleInvolved,
                  },
                ]
              : [],
          },
          {
            nome: question[7]?.nome,
            formato: question[7]?.formato,
            respostas: [
              {
                nome: data?.yourComplaint,
              },
            ],
          },
          {
            nome: question[8]?.nome,
            formato: question[8]?.formato,
            respostas: data?.witnessesName
              ? [
                  {
                    nome: data?.witnessesName,
                  },
                ]
              : [],
          },
          {
            nome: question[9]?.nome,
            formato: question[9]?.formato,
            respostas: data?.evidence
              ? [
                  {
                    nome: data?.evidence,
                  },
                ]
              : [],
          },
          {
            nome: question[10]?.nome,
            formato: question[10]?.formato,
            respostas: data?.suggestion
              ? [
                  {
                    nome: data?.suggestion,
                  },
                ]
              : [],
          },
        ],
        anexos: anexdoFile,
      })
        .then((response) => {
          if (!error.status && response.status === 200) {
            localStorage.setItem('protocolo', response?.data?.protocolo);
            navigate('/formularioEnviadoComSucesso');
            reset();
          }
        })
        .catch((e) => {
          setError({ ...error, status: true });
          console.log('Error => ', e);
        });
    } else {
      setdIparamsLess(true);
    }
  };

  const onSubmit = (data) => {
    handleSubmitPost(data);
  };

  const handleButtonClick = () => {
    document.getElementById('file').click();
  };

  const handleCheckboxChange = (event) => {
    if (!event.target.checked) {
      setScienceTerms(true);
    } else {
      setScienceTerms(false);
    }
  };

  const handleSelectChange = (event) => {
    if (event.target.value === 2) {
      setAnonymous(true);
    } else {
      setAnonymous(false);
    }
  };

  const validateFile = (value) => {
    if (value && value[0]) {
      const allowedExtensions = /(\.jpg|\.png|\.mp3|\.mp4|\.pdf|\.doc)$/i;
      if (!allowedExtensions.exec(value[0].name)) {
        setError({
          status: true,
          message:
            'O arquivo selecionado tem um formato inválido. Por favor, selecione um arquivo nos formatos JPG, PNG, MP3, MP4, PDF ou DOC.',
        });
      }
      if (value[0].size > 25000000) {
        setError({
          status: true,
          message:
            'O arquivo selecionado excede o tamanho máximo permitido (25Mb).',
        });
      }
    }
    return true;
  };

  const handleClose = () => {
    return setdIparamsLess(false);
  };

  if (!isLoading) {
    return (
      <S.Container>
        <Modal
          open={idParamsLess}
          onClose={handleClose}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h2 style={{ background: '#fff', padding: '16px' }}>
            formulário demonstrativo
          </h2>
        </Modal>
        <S.TextBox>
          <h1>Realize sua denúncia</h1>
          <p>
            É muito importante que você detalhe ao máximo o fato a ser relatado.
            Busque identificar as pessoas, o dia, se houve testemunhas e se há
            meios de provar as alegações. O detalhe do seu relato é fundamental
            para apuração da irregularidade
          </p>
        </S.TextBox>
        <S.FormBox
          onSubmit={handleSubmit(onSubmit)}
          color={
            localStorage.getItem('colors')
              ? JSON.parse(localStorage.getItem('colors'))
              : colors
          }
        >
          <S.FormCard
            style={{
              backgroundColor: localStorage.getItem('colors')
                ? JSON.parse(localStorage.getItem('colors'))?.secundaria
                : colors?.secundaria,
            }}
          >
            <h3>Você gostaria de se identificar?</h3>
            <FormControl fullWidth>
              <InputLabel id="anonymous">Selecionar</InputLabel>
              <Select
                labelId="anonymous"
                id="anonymous"
                label="Selecionar"
                size="small"
                onChange={handleSelectChange}
                defaultValue={1}
              >
                <MenuItem value={1}>Não</MenuItem>
                <MenuItem value={2}>Sim</MenuItem>
              </Select>
            </FormControl>
          </S.FormCard>
          {anonymous && (
            <S.FormCard
              className="nameEmail"
              style={{
                backgroundColor: localStorage.getItem('colors')
                  ? JSON.parse(localStorage.getItem('colors'))?.secundaria
                  : colors?.secundaria,
              }}
            >
              <TextField
                defaultValue=""
                label="Digite seu nome"
                variant="outlined"
                fullWidth
                size="small"
                {...register('name', {})}
              />
              <TextField
                defaultValue=""
                label="Digite seu e-mail"
                variant="outlined"
                fullWidth
                size="small"
                {...register('email', {})}
              />
            </S.FormCard>
          )}

          <S.FormCard
            style={{
              backgroundColor: localStorage.getItem('colors')
                ? JSON.parse(localStorage.getItem('colors'))?.terciaria
                : colors?.terciaria,
            }}
          >
            <h3>{question && question[0]?.nome}</h3>
            <FormControl fullWidth>
              <InputLabel id="collaborator">Selecionar</InputLabel>
              <Controller
                name="collaborator"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="collaborator"
                    id="collaborator"
                    label="Selecionar"
                    size="small"
                  >
                    {question &&
                      question[0]?.respostas.map((item) => (
                        <MenuItem key={item.nome} value={item.nome}>
                          {item.nome}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </S.FormCard>

          <S.FormCard
            style={{
              backgroundColor: localStorage.getItem('colors')
                ? JSON.parse(localStorage.getItem('colors'))?.terciaria
                : colors?.terciaria,
            }}
          >
            <h3>{question && question[1]?.nome}</h3>
            <FormControl fullWidth>
              <InputLabel id="typeComplaint">Selecionar</InputLabel>
              <Controller
                name="typeComplaint"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="typeComplaint"
                    id="typeComplaint"
                    label="Selecionar"
                    size="small"
                  >
                    {question &&
                      question[1]?.respostas.map((item) => (
                        <MenuItem key={item.nome} value={item.nome}>
                          {item.nome}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </S.FormCard>
          <S.FormCard
            style={{
              backgroundColor: localStorage.getItem('colors')
                ? JSON.parse(localStorage.getItem('colors'))?.terciaria
                : colors?.terciaria,
            }}
          >
            <h3>{question && question[2]?.nome}</h3>
            <FormControl fullWidth>
              <InputLabel id="branch">Selecionar</InputLabel>
              <Controller
                name="branch"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="branch"
                    id="branch"
                    label="Selecionar"
                    size="small"
                  >
                    {optionsBanch &&
                      optionsBanch?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </S.FormCard>

          <S.FormCard
            style={{
              backgroundColor: localStorage.getItem('colors')
                ? JSON.parse(localStorage.getItem('colors'))?.terciaria
                : colors?.terciaria,
            }}
          >
            <h3>{question && question[3]?.nome}</h3>
            <TextField
              defaultValue=""
              label="Insira a setor"
              variant="outlined"
              fullWidth
              size="small"
              {...register('sector', {})}
            />
          </S.FormCard>

          <S.FormCard
            style={{
              backgroundColor: localStorage.getItem('colors')
                ? JSON.parse(localStorage.getItem('colors'))?.terciaria
                : colors?.terciaria,
            }}
          >
            <h3>{question && question[4]?.nome}</h3>
            <Controller
              name="fact"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  {question &&
                    question[4]?.respostas.map((item) => (
                      <FormControlLabel
                        value={item.nome}
                        control={<Radio />}
                        label={item.nome}
                      />
                    ))}
                </RadioGroup>
              )}
            />
          </S.FormCard>

          <S.FormCard
            style={{
              backgroundColor: localStorage.getItem('colors')
                ? JSON.parse(localStorage.getItem('colors'))?.terciaria
                : colors?.terciaria,
            }}
          >
            <h3>{question && question[5]?.nome}</h3>
            <Controller
              name="aware"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  {question &&
                    question[5]?.respostas.map((item) => (
                      <FormControlLabel
                        value={item.nome}
                        control={<Radio />}
                        label={item.nome}
                      />
                    ))}
                </RadioGroup>
              )}
            />
          </S.FormCard>

          <S.FormCard
            style={{
              backgroundColor: localStorage.getItem('colors')
                ? JSON.parse(localStorage.getItem('colors'))?.terciaria
                : colors?.terciaria,
            }}
          >
            <h3>{question && question[6]?.nome}</h3>
            <TextField
              defaultValue=""
              label="Digite aqui os nomes..."
              variant="outlined"
              fullWidth
              size="small"
              multiline={true}
              rows={5}
              {...register('peopleInvolved', {})}
            />
          </S.FormCard>

          <S.FormCard
            style={{
              backgroundColor: localStorage.getItem('colors')
                ? JSON.parse(localStorage.getItem('colors'))?.terciaria
                : colors?.terciaria,
            }}
          >
            <h3>{question && question[7]?.nome}</h3>
            <p>
              Descreva o mais detalhadamente possível o que aconteceu, indicando
              o(s) nome(s) da(s) pessoa(s) que está(ão) envolvida(s), bem como
              quando e onde o fato ocorreu e se ainda continua ocorrendo.
            </p>
            <TextField
              defaultValue=""
              label="Insira aqui sua denúncia..."
              variant="outlined"
              fullWidth
              size="small"
              multiline={true}
              rows={5}
              {...register('yourComplaint', { required: true })}
            />
            <span style={{ color: '#f00' }}>
              {errors?.yourComplaint ? 'campo obrigatório' : ''}
            </span>
          </S.FormCard>

          <S.FormCard
            style={{
              backgroundColor: localStorage.getItem('colors')
                ? JSON.parse(localStorage.getItem('colors'))?.terciaria
                : colors?.terciaria,
            }}
          >
            <h3>{question && question[8]?.nome}</h3>
            <TextField
              defaultValue=""
              label="Indique aqui as testemunhas..."
              variant="outlined"
              fullWidth
              size="small"
              multiline={true}
              rows={5}
              {...register('witnessesName', {})}
            />
          </S.FormCard>

          <S.FormCard
            style={{
              backgroundColor: localStorage.getItem('colors')
                ? JSON.parse(localStorage.getItem('colors'))?.terciaria
                : colors?.terciaria,
            }}
          >
            <h3>{question && question[9]?.nome}</h3>
            <p>
              Quais e em que lugar(es) podem ser encontradas evidências sobre o
              fato denunciado? Existem documentos que comprovam esse fato? Em
              caso positivo, onde podem ser encontrados? Especifique da forma
              mais detalhada possível. Lembre-se: qualquer informação pode ser
              útil, por mais irrelevante que ela pareça para você.
              <span>
                ATENÇÃO: Não remova ou entre em contato com qualquer evidência
                ou prova, pois a investigação pode ser prejudicada.
              </span>
            </p>

            <TextField
              defaultValue=""
              label="Indique aqui as evidências..."
              variant="outlined"
              fullWidth
              size="small"
              multiline={true}
              rows={5}
              {...register('evidence', {})}
            />
          </S.FormCard>

          <S.FormCard
            style={{
              backgroundColor: localStorage.getItem('colors')
                ? JSON.parse(localStorage.getItem('colors'))?.terciaria
                : colors?.terciaria,
            }}
          >
            <h3>{question && question[10]?.nome}</h3>
            <TextField
              defaultValue=""
              label="Digite aqui sua sugestão..."
              variant="outlined"
              fullWidth
              size="small"
              multiline={true}
              rows={5}
              {...register('suggestion', {})}
            />
          </S.FormCard>

          <S.FormCard
            style={{
              backgroundColor: localStorage.getItem('colors')
                ? JSON.parse(localStorage.getItem('colors'))?.secundaria
                : colors?.secundaria,
            }}
          >
            <h3>Possui arquivos relacionados à denúncia para anexar?</h3>
            <S.formCardFile
              color={
                localStorage.getItem('colors')
                  ? JSON.parse(localStorage.getItem('colors'))
                  : colors
              }
            >
              <TextField
                fullWidth
                label={
                  file && file[0]?.name
                    ? file[0]?.name
                    : 'Adicione um arquivo...'
                }
                variant="outlined"
                size="small"
                htmlFor="file"
                value={selectedFileName}
                disabled
              />
              <button type="button" onClick={handleButtonClick}>
                Anexar
              </button>
              <Controller
                name="file"
                control={control}
                render={({ field, onChange }) => (
                  <input
                    accept=".JPG, .PNG, .MP3, .MP4, .PDF, .DOC"
                    id="file"
                    style={{ display: 'none' }}
                    type="file"
                    ref={(e) => {
                      field.ref.current = e;
                    }}
                    {...register('file', { validate: validateFile })}
                  />
                )}
              />
            </S.formCardFile>
            <span>
              {fileAdd &&
                fileAdd.map((item, index) => (
                  <React.Fragment key={index}>
                    {item}
                    {index !== fileAdd.length - 1 && ', '}
                  </React.Fragment>
                ))}
            </span>
            <span>Arquivos JPG, PNG, MP3, MP4, PDF, DOC de até 25Mb </span>
          </S.FormCard>
          <S.FormControlLabelCustom
            control={<Checkbox onChange={handleCheckboxChange} />}
            label={
              <small>
                Declaro que os fatos narrados são <Link>verdadeiros e relatados de boa-fé.</Link>
              </small>
            }
          />
          <button
            className="buttonSubmit"
            type="submit"
            disabled={scienceTerms}
          >
            {loading ? 'carregando...' : ' Enviar denúncia'}
          </button>
          {error.status ? (
            <span style={{ color: '#f00' }}>{error.message}</span>
          ) : (
            <span>
             Sua denúncia será recebida de forma segura e confidencial.
            </span>
          )}
        </S.FormBox>
      </S.Container>
    );
  }
};
