import React from 'react';
import { ProtocolState } from './ProtocolStates';
import { ParamsState } from './ParamsState';

export const Providers = ({ children }) => {
  return (
    <ParamsState>
      <ProtocolState>{children}</ProtocolState>
    </ParamsState>
  );
};
