import React, { Fragment } from 'react';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import * as S from './style';
import { useParamsState } from '../../Providers/ParamsState';

export const ImportantReadings = () => {
  const { isLoading, colors, images, urlDownloads } = useParamsState();

  if (!isLoading) {
    return (
      <S.Container id="leiturasImportantes">
        <S.TextBox
          color={
            localStorage.getItem('colors')
              ? JSON.parse(localStorage.getItem('colors'))
              : colors
          }
        >
          <h1>
            Leituras <small>importantes</small>
          </h1>
          <p>
            Aqui você encontrará o Código de Ética e principais documentos que
            detalham como a denúncia é recebida, procedimentos internos e
            possíveis consequências.
          </p>
        </S.TextBox>

        <S.ListBox>
          {urlDownloads &&
            urlDownloads.map((item) => (
              <Fragment key={item?.url}>
                {item?.url && (
                  <S.DonwloadBox
                    color={
                      localStorage.getItem('colors')
                        ? JSON.parse(localStorage.getItem('colors'))
                        : colors
                    }
                  >
                    <div className="iconBox">
                      <span>{item.icon}</span>
                    </div>
                    <h3>
                      {item.title}
                      <a href={item.url} download target="_blank">
                        Baixar arquivo <EastOutlinedIcon />
                      </a>
                    </h3>
                  </S.DonwloadBox>
                )}
              </Fragment>
            ))}
        </S.ListBox>
        <img
          src={
            localStorage.getItem('imagensBanners')
              ? JSON.parse(localStorage.getItem('imagensBanners'))
                  ?.inicioInferiorUrl
              : images?.inicioInferiorUrl
          }
          alt="imagem da seção"
        />
      </S.Container>
    );
  }
};
