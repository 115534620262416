import { useEffect } from 'react';
import { Complaint } from '../../Components/Complaint';
import { Consult } from '../../Components/Consult';
import { ImportantReadings } from '../../Components/ImportantReadings';
import { InitialSection } from '../../Components/InitialSection';
import { LegalTeam } from '../../Components/LegalTeam';
import { useParamsState } from '../../Providers/ParamsState';
import { useParams } from 'react-router-dom';

export const Home = () => {
    const { urlDownloads, connectionGetData } = useParamsState();
    const { id } = useParams();

    useEffect(() => {
        connectionGetData(id);
    }, []);

    return (
        <>
            <InitialSection urlDownloads={urlDownloads} />
            <Consult />
            <Complaint />
            <LegalTeam />
            {urlDownloads && <ImportantReadings />}
        </>
    );
};
