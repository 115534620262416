import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding: 16px;
  @media only screen and (min-width: 600px) {
    padding: 48px;
  }
  @media only screen and (min-width: 1024px) {
   padding: 80px;
  }
`;

export const TextBox = styled.div`
  width: 90%;
  text-align: center;
  margin: 0 auto 32px;
  h1 {
    font-size: 24px;
    margin-bottom: 12px;
    small {
      color:${({ color }) => color.primaria} ;
    }
  }
  p {
    font-size: 16px;
    color: #5f6d7e;
  }
`;

export const CardsBox = styled.div`
  background-color:${({ color }) => color.terciaria};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 40px;
  @media only screen and (min-width: 600px) {
    gap: 14px;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const Card = styled.div`
  text-align: center;
  svg {
    background-color: #fff;
    padding: 8px;
    path{
      fill:${({ color }) => color.primaria};
    }
  }
  h3 {
    font-size: 22px;
    margin-bottom: 8px;
  }
  p {
    font-size: 16px;
    color: #5f6d7e;
  }

  @media only screen and (min-width: 600px) {
    width: 47%;
  }
  @media only screen and (min-width: 1024px) {
    width: 23%;
  }
`;
