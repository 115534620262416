import React, { useEffect, useState } from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { useParamsState } from '../../Providers/ParamsState';
import * as S from './style';

export const LegalTeam = () => {
  const [idParams, setIdParams] = useState(null);
  const { isLoading, colors, images } = useParamsState();

  useEffect(() => {
    setIdParams(localStorage.getItem('idParams'));
  }, [localStorage.getItem('idParams')]);

  if (!isLoading) {
    return (
      <S.Container id="funcionamento">
        <S.TextBox
          color={
            localStorage.getItem('colors')
              ? JSON.parse(localStorage.getItem('colors'))
              : colors
          }
        >
          <h1>
            Processo simples, claro e totalmente
            <small> imparcial</small>.
          </h1>
          <p>
            Para garantir a imparcialidade e a confidencialidade, suas denúncias
            são recebidas e triadas pelo Martinelli Advogados, um escritório de
            advocacia especializado em integridade e ética corporativa
          </p>
        </S.TextBox>
        <img
          src={
            localStorage.getItem('imagensBanners') !== 'undefined'
              ? JSON.parse(localStorage.getItem('imagensBanners'))
                  ?.inicioMeioUrl
              : images?.inicioMeioUrl
          }
          alt="imagem da seção"
        />
        <S.ListBox
          color={
            localStorage.getItem('colors')
              ? JSON.parse(localStorage.getItem('colors'))
              : colors
          }
        >
          <p className="title">
            {`Os relatos passam por uma análise jurídica a fim de evitar conflito de
          interesse ou quebra de imparcialidade ao receber as denúncias. Uma vez
          analisadas, serão encaminhadas, com as devidas instruções, apenas para
          as pessoas necessárias na ${
            idParams ? idParams.replace('-', ' ') : 'Martinelli'
          }`}
          </p>
          <p>
            <CheckOutlinedIcon />
            Sua denúncia é enviada sem qualquer registro de identidade ou IP
          </p>
          <p>
            <CheckOutlinedIcon />O processo é auditado por uma equipe externa e
            independente
          </p>
          <p>
            <CheckOutlinedIcon />
            Você acompanha o processo etapa por etapa
          </p>
          <a href="/formularioDeReclamacao">
            Realizar denúncia
            <EastOutlinedIcon />
          </a>
        </S.ListBox>
      </S.Container>
    );
  }
};
