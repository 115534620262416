import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video ,button{
        margin: 0;
        padding: 0;
        border: 0;
    }
    body{
        box-sizing: border-box;

    }

    a,button{
        cursor: pointer;
        text-decoration: none;
        outline: none;
        border: none;
        background-color: transparent;
    }

    input,select{
        outline: none;
        background-color: transparent;
    }

    ul,li{
        list-style:none;
    }

    .App{
        max-width:1440px;
        width:100%;
        min-width:360px;
        margin: 0 auto;
        overflow: hidden;
        box-sizing: border-box;
        color:#272D37;
        font-family: 'Inter', sans-serif;
    }

    a{
        :visited{
            color:#272D37;
        }
    }

    h1,h2,h3,h4,h5,h6{
        line-height: normal;
    }
`;
