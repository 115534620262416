import styled from 'styled-components';

export const Container = styled.section`
  padding: 32px 16px;

  img {
    display: none;
  }

  @media only screen and (min-width: 600px) {
    padding: 40px 32px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 64px 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    img {
      display: block;
      order: 2;
      width: 48%;
      max-width: 616pxpx;
      margin: 0 0 48px;
    }
  }
`;
export const TextBox = styled.div`
  h1 {
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.02em;
    margin: 0 auto 12px;

    small {
      color: ${({ color }) => color.primaria};
    }
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #5f6d7e;
    width: 100%;
    margin: 0 auto 32px;
  }

  @media only screen and (min-width: 600px) {
    h1 {
      margin: 0 auto 16px;
    }
    p {
      width: 100%;
      margin: 0 auto 40px;
    }
  }

  @media only screen and (min-width: 1024px) {
    width: 100%;
  }
`;

export const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media only screen and (min-width: 1024px) {
    width: 48%;
    max-width: 476px;
  }
`;

export const DonwloadBox = styled.div`
  display: flex;
  gap: 16px;

  .iconBox {
    width: 48px;
    height: 48px;
  }
  span {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${({ color }) => color.primaria};
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      path {
        fill: #fff;
      }
    }
  }

  h3 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: fit-content;
    font-size: 22px;
    line-height: 30px;
  }

  a {
    color: ${({ color }) => color.primaria};
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 15px;
    width: fit-content;
    :visited {
      color: ${({ color }) => color.primaria};
    }
  }
`;
