/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TextField } from '@mui/material';
import { ImgHeader } from '../../Components/ImgHeader';
import { ReactComponent as IconAmpulheta } from '../../assets/Icon_ampulheta.svg';
import { handleGetConsultation } from '../../Services';
import CheckIcon from '@mui/icons-material/Check';
import { useParamsState } from '../../Providers/ParamsState';
import { handlePostResponse, handlePostUrlFile } from '../../Services';

import { LinearProgress } from '@mui/joy';

import * as S from './style';

export const Consultation = () => {
  const [data, setData] = useState();
  const [idParams, setIdParams] = useState(null);
  const [status, setStatus] = useState('');
  const [anexdoFile, setAnexoFile] = useState([]);
  const [file, setFile] = useState(null);
  const [selectedFileName] = useState('');
  const [nomeComercial] = useState(localStorage.getItem('idParams'));
  const [corporacaoId] = useState(localStorage.getItem('idCorporacao'));
  const [fileAdd, setFileAdd] = useState([]);
  const [complaintId, setComplaintId] = useState('');
  const [loading, setLoading] = useState(false);
  const [disableSend, setDisableSend] = useState(true);
  const [loadAnexo, setLoadAnexo] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: 'Error na hora de enviar respostas',
  });
  const [showMoreValue, setShowMoreValue] = useState(3);
  const { protocol } = useParams();
  const { isLoading, colors } = useParamsState();

  const { control, handleSubmit, reset, register, watch } = useForm();

  const textareaRef = useRef(null);

  useEffect(() => {
    setIdParams(localStorage.getItem('idParams'));
  }, [localStorage.getItem('idParams')]);

  const consultationRequest = () => {
    handleGetConsultation(`denuncia/protocolo/${protocol}`)
      .then((response) => {
        setComplaintId(response?.data?.id);
        getStatus(response?.data?.status);
        setData(response.data);
      })
      .catch((e) => console.log('Error => ', e));
  };

  useEffect(() => {
    consultationRequest();
  }, []);

  useEffect(() => {
    setFile(watch('file'));
  }, [watch('file')]);

  useEffect(() => {
    if (watch('suggestion')) {
      setDisableSend(false);
    } else {
      setDisableSend(true);
    }
  }, [watch('suggestion')]);

  useEffect(() => {
    if (file) {
      handleFiles();
    }
  }, [file]);

  const handleFiles = () => {
    setDisableSend(true);
    setLoadAnexo(true);
    const formData = new FormData();
    formData.append('file', file[0]);
    formData.append('fileName', file[0]?.name);
    if (file[0]?.name) {
      setFileAdd([...fileAdd, file[0]?.name]);
    }
    handlePostUrlFile(
      `storage?corporacao=${corporacaoId}&fileName=${file[0]?.name}&contentType=${file[0]?.type}`,
      formData,
    )
      .then((response) => {
        setAnexoFile([
          ...anexdoFile,
          {
            observacao: '',
            nome: file[0]?.name,
            url: response?.data.toString(),
          },
        ]);
        setDisableSend(false);
      })
      .catch((e) => console.log('error => ', e))
      .finally(() => {
        setLoadAnexo(false);
      });
  };

  const getStatus = (status) => {
    switch (status) {
      case 'ABERTO':
        setStatus({
          text: 'Em aberto',
        });
        break;
      case 'TRIADO':
        setStatus('Está na fila de triagem');
        break;
      case 'APURADO':
        setStatus('Foi apurado');
        break;
      case 'ANDAMENTO':
        setStatus('Está em adamento');
        break;
      case 'CONCLUIDO':
        setStatus('Está Concluído');
        break;
      default:
        break;
    }
  };

  const handleSubmitPost = (data) => {
    setLoading(true);

    if (nomeComercial !== null) {
      handlePostResponse('denuncia/tratativa/triagem/denunciante', {
        DenunciaId: complaintId,
        Mensagem: `Denunciante: ${data.suggestion}`,
        Anexos: anexdoFile,
      })
        .then((response) => {
          if (!error.status && response.status === 200) {
            localStorage.setItem('protocolo', response?.data?.protocolo);
            consultationRequest();
            setLoading(false);
            setFileAdd([]);
            reset();
            setDisableSend(true);
          }

          setAnexoFile([]);
        })
        .catch((e) => {
          setError({ ...error, status: true });
          console.log('Error => ', e);
          setLoading(false);
          setDisableSend(true);
          setAnexoFile([]);
        });
    }
  };

  const onSubmit = (data) => {
    handleSubmitPost(data);
  };

  const handleButtonClick = () => {
    document.getElementById('file').click();
  };

  const validateFile = (value) => {
    if (value && value[0]) {
      const allowedExtensions = /(\.jpg|\.png|\.mp3|\.mp4|\.pdf|\.doc)$/i;
      if (!allowedExtensions.exec(value[0].name)) {
        setError({
          status: true,
          message:
            'O arquivo selecionado tem um formato inválido. Por favor, selecione um arquivo nos formatos JPG, PNG, MP3, MP4, PDF ou DOC.',
        });
      }
      if (value[0].size > 25000000) {
        setError({
          status: true,
          message:
            'O arquivo selecionado excede o tamanho máximo permitido (25Mb).',
        });
      }
    }
    return true;
  };

  const handleShowMore = (value) => {
    if (showMoreValue > 3) {
      setShowMoreValue(3);
    } else {
      setShowMoreValue(value);
    }
  };

  if (!isLoading) {
    return (
      <S.Conatiner>
        <ImgHeader />
        <S.Card
          color={
            localStorage.getItem('colors')
              ? JSON.parse(localStorage.getItem('colors'))
              : colors
          }
        >
          <h1>
            Status da denúncia Nº: <span>{protocol}</span>
          </h1>

          {data &&
            data?.tratativas
              .slice()
              .reverse()
              .slice(0, showMoreValue)
              .map((item) => (
                <S.StatusCard
                  key={item.id}
                  color={
                    localStorage.getItem('colors')
                      ? JSON.parse(localStorage.getItem('colors'))
                      : colors
                  }
                  status={data?.status === 'CONCLUIDO' ? true : false}
                >
                  <S.IconBox
                    color={
                      localStorage.getItem('colors')
                        ? JSON.parse(localStorage.getItem('colors'))
                        : colors
                    }
                  >
                    <span
                      className={
                        data?.status === 'CONCLUIDO' ? 'complete' : 'inProgress'
                      }
                    >
                      {data && data?.status === 'CONCLUIDO' ? (
                        <CheckIcon />
                      ) : (
                        <IconAmpulheta />
                      )}
                    </span>
                  </S.IconBox>
                  <S.CardsMessage>
                    <h1>Status: {status}</h1>
                    <S.AreaMessage>
                      <span className="spanDate">
                        {format(
                          new Date(item?.mensagem?.criadoEm),
                          'dd/MM/yyyy',
                        )}
                      </span>
                      <pre ref={textareaRef}>{item?.mensagem?.descricao}</pre>
                    </S.AreaMessage>

                    {item?.anexos?.length > 0 && (
                      <S.anexoBox
                        color={
                          localStorage.getItem('colors')
                            ? JSON.parse(localStorage.getItem('colors'))
                            : colors
                        }
                      >
                        {item?.anexos?.map((item, index) => (
                          <Fragment>
                            <a
                              className="bgColor"
                              href={item.url}
                              target="_Blank"
                              key={index}
                              rel="noreferrer"
                            >
                              Anexo: {index + 1}
                            </a>
                          </Fragment>
                        ))}
                      </S.anexoBox>
                    )}
                  </S.CardsMessage>
                </S.StatusCard>
              ))}
        </S.Card>

        <S.ShowMore
          color={
            localStorage.getItem('colors')
              ? JSON.parse(localStorage.getItem('colors'))
              : colors
          }
          onClick={() => handleShowMore(data?.tratativas.length)}
        >
          {showMoreValue > 3 ? 'Mostrar menos' : 'Mostrar mais'}
        </S.ShowMore>

        {status !== 'Está Concluido' && (
          <S.Card
            color={
              localStorage.getItem('colors')
                ? JSON.parse(localStorage.getItem('colors'))
                : colors
            }
            style={{ marginTop: '50px' }}
          >
            <h2>Gostaria de enviar mais informações sobre a denúncia?</h2>
            <S.FormBox
              onSubmit={handleSubmit(onSubmit)}
              color={
                localStorage.getItem('colors')
                  ? JSON.parse(localStorage.getItem('colors'))
                  : colors
              }
            >
              <S.FormCard
                style={{
                  backgroundColor: localStorage.getItem('colors')
                    ? JSON.parse(localStorage.getItem('colors'))?.terciaria
                    : colors?.terciaria,
                }}
              >
                <TextField
                  defaultValue=""
                  label="Digite aqui sua mensagem..."
                  variant="outlined"
                  fullWidth
                  size="small"
                  multiline={true}
                  rows={5}
                  {...register('suggestion', {})}
                />
              </S.FormCard>

              <S.FormCard
                style={{
                  backgroundColor: localStorage.getItem('colors')
                    ? JSON.parse(localStorage.getItem('colors'))?.secundaria
                    : colors?.secundaria,
                }}
              >
                <h3>Possui arquivos relacionados à denúncia para anexar?</h3>
                <S.formCardFile
                  color={
                    localStorage.getItem('colors')
                      ? JSON.parse(localStorage.getItem('colors'))
                      : colors
                  }
                >
                  <TextField
                    fullWidth
                    label={
                      file && file[0]?.name
                        ? file[0]?.name
                        : 'Adicione um arquivo...'
                    }
                    variant="outlined"
                    size="small"
                    htmlFor="file"
                    value={selectedFileName}
                    disabled
                  />
                  <button
                    type="button"
                    onClick={handleButtonClick}
                    disabled={disableSend}
                    className="anexoBtn"
                    style={{ position: 'relative' }}
                  >
                    {loadAnexo ? 'carregando...' : 'Anexar'}
                  </button>
                  <Controller
                    name="file"
                    control={control}
                    render={({ field, onChange }) => (
                      <input
                        accept=".JPG, .PNG, .MP3, .MP4, .PDF, .DOC"
                        id="file"
                        style={{ display: 'none' }}
                        type="file"
                        ref={(e) => {
                          field.ref.current = e;
                        }}
                        {...register('file', { validate: validateFile })}
                      />
                    )}
                  />
                </S.formCardFile>
                {loadAnexo ? (
                  <S.formCardFile
                    style={{ marginTop: '10px' }}
                    color={
                      localStorage.getItem('colors')
                        ? JSON.parse(localStorage.getItem('colors'))
                        : colors
                    }
                  >
                    <LinearProgress color="neutral" variant={'soft'} />
                  </S.formCardFile>
                ) : (
                  <span>
                    {fileAdd &&
                      fileAdd.map((item, index) => (
                        <React.Fragment key={index}>
                          {item}
                          {index !== fileAdd.length - 1 && ', '}
                        </React.Fragment>
                      ))}
                  </span>
                )}
                <span>Arquivos JPG, PNG, MP3, MP4, PDF, DOC de até 25Mb </span>
              </S.FormCard>
              <button
                className="buttonSubmit"
                type="submit"
                disabled={disableSend}
              >
                {loading ? 'carregando...' : ' Enviar'}
              </button>
              {error.status && (
                <span style={{ color: '#f00' }}>{error.message}</span>
              )}
            </S.FormBox>
          </S.Card>
        )}

        <S.ButtonBox
          color={
            localStorage.getItem('colors')
              ? JSON.parse(localStorage.getItem('colors'))
              : colors
          }
        >
          <a href={`/${idParams}#consulta`} className="bgColor">
            Nova consulta
          </a>
          <a href={`/${idParams}`}>
            <ArrowBackIcon /> Voltar
          </a>
        </S.ButtonBox>
      </S.Conatiner>
    );
  }
};
