import styled from 'styled-components';

export const Container = styled.section`
  padding: 32px 16px;
  @media only screen and (min-width: 600px) {
    padding: 40px 32px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 64px 80px;
  }
`;

export const TextBox = styled.div`
  h1 {
    margin-bottom: 16px;
    font-size: px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.02em;
    margin: 0 auto 12px;
    span {
      color: ${({color}) => color.primaria}
    }
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #5f6d7e;
    width: 90%;
    margin: 0 auto 32px;
    max-width: 800px;
  }

  @media only screen and (min-width: 600px) {
    h1 {
      font-size: 32px;
      margin: 0 auto 16px;
    }
    p {
      width: 100%;
      margin: 0 auto 40px;
    }
  }

  @media only screen and (min-width: 1024px) {
    width: 100%;
  }
`;

export const AccordionsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
