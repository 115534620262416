import React, { useEffect, useState } from 'react';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { useParamsState } from '../../Providers/ParamsState';
import * as S from './style';

export const InitialSection = ({ urlDownloads }) => {
    const [idParams, setIdParams] = useState(null);
    const [comercialName, setComercialName] = useState(null)

    const { isLoading, colors, images } = useParamsState();

    useEffect(() => {
        setIdParams(localStorage.getItem('idParams'));
        setComercialName(localStorage.getItem('nomeComercial'));

    }, [localStorage.getItem('idParams'), localStorage.getItem('nomeComercial')]);

    if (!isLoading) {
        return (
            <S.Container>
                <img
                    src={
                        localStorage.getItem('imagensBanners') !== 'undefined'
                            ? JSON.parse(localStorage.getItem('imagensBanners'))
                                ?.inicioSuperiorUrl
                            : images?.inicioSuperiorUrl
                    }
                    alt="imagem de sensação de segurança e leveza"
                />
                <S.TextBox
                    color={
                        localStorage.getItem('colors')
                            ? JSON.parse(localStorage.getItem('colors'))
                            : colors
                    }
                >
                    <span>
                        <VisibilityOffOutlinedIcon />
                        DENÚNCIAS 100% ANÔNIMAS
                    </span>
                    <h1>
                        Confidencialidade e Imparcialidade. <small>Juntos.</small>
                    </h1>
                    <p >
                        {`A ${comercialName ? comercialName : 'Martinelli'
                            } conta com uma equipe jurídica independente pronta para receber sua denúncia com confidencialidade, anonimato, segurança e ética.`}
                    </p>
                    <S.ButtonBox
                        color={
                            localStorage.getItem('colors')
                                ? JSON.parse(localStorage.getItem('colors'))
                                : colors
                        }
                    >
                        <a href="/formularioDeReclamacao" className="bgColor">
                            Realizar denúncia
                        </a>
                        {urlDownloads &&
                            <a href={`/${idParams}#leiturasImportantes`}>
                                Como funciona? <EastOutlinedIcon />
                            </a>}
                    </S.ButtonBox>
                </S.TextBox>
            </S.Container>
        );
    }
};
