import styled from 'styled-components';

export const Container = styled.section`
  padding: 32px 16px;
  img {
    width: 100%;
    margin: 0 auto 32px;
  }

  @media only screen and (min-width: 600px) {
    padding: 40px 32px;
    img {
      width: 80%;
      margin: 0 auto 48px;
      display: block;
    }
  }
  @media only screen and (min-width: 1024px) {
    padding: 64px 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    img {
      order: 2;
      width: 48%;
      max-width: 560px;
      margin: 0 0 48px;
    }
  }
`;
export const TextBox = styled.div`
  h1 {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.02em;
    margin: 0 auto 12px;

    small {
      color: ${({ color }) => color.primaria};
    }
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #5f6d7e;
    width: 90%;
    margin: 0 auto 32px;
  }

  @media only screen and (min-width: 600px) {
    h1 {
      font-size: 32px;
      margin: 0 auto 16px;
    }
    p {
      width: 100%;
      margin: 0 auto 40px;
    }
  }

  @media only screen and (min-width: 1024px) {
    width: 100%;
  }
`;

export const ListBox = styled.div`
  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #5f6d7e;
  }
  p {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
    gap: 8px;
    svg {
      path {
        color: ${({ color }) => color.primaria};
      }
    }
  }

  a {
    background-color: ${({ color }) => color.primaria};
    color: #fff;
    padding: 12px 18px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 22px;
    width: fit-content;
    :visited {
      color: #fff;
    }
  }

  @media only screen and (min-width: 600px) {
    h2 {
      font-size: 32px;
    }
    a {
      margin-top: 30px;
    }
  }

  @media only screen and (min-width: 1024px) {
    width: 48%;
    max-width: 532px;
  }
`;
