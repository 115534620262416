import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 16px;
  img {
    width: 100%;
    margin-bottom: 32px;
    margin-top: 8px;
  }

  @media only screen and (min-width: 600px) {
    padding: 0 48px;
    img {
      margin: 20px auto 28px;
    }
  }

  @media only screen and (min-width: 1024px) {
    gap: 16px;
    justify-content: center;
    padding: 50px 80px 0;
    flex-direction: row;

    img {
      max-width: 570px;
      order: 2;
      margin-right: -79px;
    }
  }
`;

export const TextBox = styled.div`
  width: 100%;
  @media only screen and (min-width: 1024px) {
    max-width: 524px;
  }
  span {
    color: ${({ color }) => color.primaria};
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    path {
      color: initial;
    }

    @media only screen and (min-width: 600px) {
      font-size: 14px;
    }
  }
  h1 {
    margin-bottom: 16px;
    font-size: 32px;

    small {
      color: ${({ color }) => color.primaria};
    }

    @media only screen and (min-width: 600px) {
      font-size: 46px;
      margin-bottom: 24px;
    }
  }
  p {
    font-size: 18px;
    color: #5f6d7e;
  }
`;

export const ButtonBox = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  font-size: 15px;
  margin-top: 32px;
  a {
    color: ${({ color }) => color.primaria};
    display: flex;
    align-items: center;
    gap: 6px;
    :visited {
      color: ${({ color }) => color.primaria};
    }
  }
  .bgColor {
    background-color: ${({ color }) => color.primaria};
    color: #fff;
    padding: 12px 18px;
    border-radius: 6px;
    :visited {
      color: #fff;
    }
  }

  @media only screen and (min-width: 600px) {
    margin-top: 60px;
  }
`;
