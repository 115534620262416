import styled from 'styled-components';

export const Conatiner = styled.section`
  padding: 72px 16px 64px;
  @media only screen and (min-width: 600px) {
    padding: 72px 48px 64px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 72px 80px 64px;
  }
`;
export const Card = styled.div`
  border-radius: 10px;
  background-color: #f0faf0;
  padding: 16px;
  color: #1a451d;

  h1 {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.02em;
    margin-bottom: 8px;
  }
  p {
    :nth-child(2) {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
    }
    :nth-child(3) {
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      letter-spacing: -0.01em;
      margin: 32px 0;
    }
    :nth-child(4) {
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.1px;
      margin-bottom: 32px;
    }
  }
  a {
    color: #2d8a39;
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
  }

  @media only screen and (min-width: 600px) {
    padding: 32px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 64px;
  }
`;
