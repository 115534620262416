import styled from 'styled-components';

export const Container = styled.section`
  background-color: #2b63d9;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #fff;
  padding: 32px 16px;
  @media only screen and (min-width: 600px) {
    padding: 40px 32px;
    gap: 32px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 64px 80px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const TextBox = styled.div`
  @media only screen and (min-width: 1024px) {
    width: 632px;
  }

  h1 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
    @media only screen and (min-width: 600px) {
      font-size: 32px;
    }
    @media only screen and (min-width: 1024px) {
      margin: 0;
      margin-bottom: 24px;
    }
  }

  p {
    color: #e8effd;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.1px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  @media only screen and (min-width: 600px) {
    gap: 0;
    flex-direction: row;
  }
  @media only screen and (min-width: 1024px) {
    width: 440px;
  }
  /* .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    background-color: #fff !important;
  } */

  input {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
    background-color: #fff !important;

    @media only screen and (min-width: 600px) {
      border-radius: 6px 0 0 6px;
      width: 100%;
    }
  }
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #919ba7;
    background-color: transparent;
  }
  .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
    color: #919ba7;
  }
  .MuiTextField-root {
    fieldset {
      border: #fff !important;
    }
  }
  button {
    color: #fff;
    background: #437ef7;
    width: 100%;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
    border-radius: 5px;
    padding: 8px 12px;
    border: 1px solid #437ef7;
    height: 40px;
    @media only screen and (min-width: 600px) {
      border-radius: 0 6px 6px 0;
      padding: 12px 18px;
      width: fit-content;
    }
  }
`;
