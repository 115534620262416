import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ImgHeader } from '../../Components/ImgHeader';
import { useParamsState } from '../../Providers/ParamsState';
import * as S from './style';

export const ComplaintSuccessfully = () => {
  const [protocol] = useState(
    localStorage.getItem('protocolo') &&
      localStorage.getItem('protocolo').toString(),
  );
  const [idParams, setIdParams] = useState(null);

  const { isLoading } = useParamsState();

  useEffect(() => {
    setIdParams(localStorage.getItem('idParams'));
  }, [localStorage.getItem('idParams')]);

  if (!isLoading) {
    return (
      <S.Conatiner>
        <ImgHeader />
        <S.Card>
          <h1>Denúncia enviada com sucesso!</h1>
          <p>O número do protocolo referente à denúncia recém realizada é:</p>
          <p>{protocol}</p>
          <p>
            Guarde este número para que seja possível consultar o andamento
            posteriormente.
          </p>

          <Link to={`/${idParams}`}>
            <KeyboardBackspaceIcon /> Volta para a página inicial
          </Link>
        </S.Card>
      </S.Conatiner>
    );
  }
};
