import React, { useEffect, useState } from 'react';
import image from '../../assets/denuncia_img.jpg';
import styled from 'styled-components';
import { useParamsState } from '../../Providers/ParamsState';

const ImgBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 16px;

  img {
    width: 100%;
    max-width: 1280px;

    border-radius: 8px;
    @media only screen and (min-width: 600px) {
      border-radius: 16px;
    }
  }
  @media only screen and (min-width: 600px) {
    margin-bottom: 32px;
  }
  @media only screen and (min-width: 1024px) {
    margin-bottom: 48px;
  }
`;

export const ImgHeader = () => {
  const { isLoading, images } = useParamsState();

  if (!isLoading) {
    return (
      <ImgBox>
        <img
          src={
            localStorage.getItem('imagensBanners') !== 'undefined'
              ? JSON.parse(localStorage.getItem('imagensBanners'))
                  ?.denunciaSuperioUrl
              : images?.denunciaSuperioUrl
          }
          alt=""
        />
      </ImgBox>
    );
  }
};
