import React, { createContext, useContext, useEffect, useState } from 'react';
import { ReactComponent as IconeEtica } from '../../assets/Icon_etica.svg';
import { ReactComponent as IconPessoa } from '../../assets/Icone_persona.svg';
import { ReactComponent as IconCanal } from '../../assets/Icon_canal.svg';
import imgInitialSectionDefault from '../../assets/teste.png';
import imageLegalTeamDefault from '../../assets/home_img2.svg';
import imageImportantReadingsDefault from '../../assets/home_img3.svg';
import denunciaSuperioUrlDefault from '../../assets/denuncia_img.jpg';
import { handleGetData } from '../../Services';

const ParamsStateContext = createContext();

export const ParamsState = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [colors] = useState({
    primaria: '#4580F7',
    secundaria: '#E8EFFD',
    terciaria: '#F6F9FE',
  });

  const [images] = useState({
    denunciaSuperioUrl: denunciaSuperioUrlDefault,
    inicioInferiorUrl: imageImportantReadingsDefault,
    inicioMeioUrl: imageLegalTeamDefault,
    inicioSuperiorUrl: imgInitialSectionDefault,
  });

  const [urlDownloads, setUrlDownloads] = useState(null);

  const connectionGetData = (id) => {
    setIsLoading(true);
    if (id !== localStorage.getItem('idParams')) {
      localStorage.clear();
    }

    handleGetData(`corporacao/${id}`)
      .then((response) => {
        console.log('response', response)
        response?.data?.configuracao?.banners
          ? localStorage.setItem(
              'imagensBanners',
              JSON.stringify(response?.data?.configuracao?.banners),
            )
          : localStorage.setItem('imagensBanners', 'undefined');

        response?.data?.configuracao?.logoUrl
          ? localStorage.setItem('logo', response?.data?.configuracao?.logoUrl)
          : localStorage.setItem('logo', 'undefined');

        localStorage.setItem('idCorporacao', response?.data?.id);
        localStorage.setItem('nomeComercial', response?.data?.nomeComercial);
        localStorage.setItem(
          'colors',
          JSON.stringify({
            primaria: response?.data?.configuracao?.corPrimaria,
            secundaria: response?.data?.configuracao?.corSecundaria,
            terciaria: response?.data?.configuracao?.corTerciaria,
          }),
        );

        if (
          response?.data?.configuracao?.codigoCondutaUrl ||
          response?.data?.configuracao?.politicaAssedioUrl ||
          response?.data?.configuracao?.politicaCanalUrl
        ) {
          setUrlDownloads([
            {
              title: `Código de Ética ${id}`,
              icon: <IconeEtica />,
              url: response?.data?.configuracao?.codigoCondutaUrl.toString(),
            },
            {
              title: ` Políticas de Combate ao Assédio`,
              icon: <IconPessoa />,
              url: response?.data?.configuracao?.politicaAssedioUrl.toString(),
            },
            {
              title: `Políticas do Canal de Denúncias`,
              icon: <IconCanal />,
              url: response?.data?.configuracao?.politicaCanalUrl.toString(),
            },
          ]);
        } else {
          setUrlDownloads(null);
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          localStorage.clear();
        }
        localStorage.setItem('imagensBanners', 'undefined');
        localStorage.setItem('logo', 'undefined');
      })
      .finally(() => setIsLoading(false));
    localStorage.setItem('idParams', id);
  };

  useEffect(() => {
    localStorage.setItem('downloadsDocuments', JSON.stringify(urlDownloads));
  }, [urlDownloads]);

  return (
    <ParamsStateContext.Provider
      value={{
        isLoading,
        setIsLoading,
        colors,
        images,
        urlDownloads,
        connectionGetData,
      }}
    >
      {children}
    </ParamsStateContext.Provider>
  );
};

export const useParamsState = () => useContext(ParamsStateContext);
