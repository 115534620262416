import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { GlobalStyle } from './GlobalStyle';
import { Header } from './Components/Header';
import { Footer } from './Components/Footer';
import { Home } from './Pages/Home';
import { AccordionSection } from './Components/AccordionSection';
import { Merchandising } from './Components/Merchandising';
import { ComplaintForm } from './Pages/ComplaintForm';
import { ComplaintSuccessfully } from './Pages/ComplaintSuccessfully';
import { Consultation } from './Pages/Consultation';

export const App = () => {
  const [idParams, setIdParams] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setIdParams(localStorage.getItem('idParams'));
  }, [localStorage.getItem('idParams')]);

  

  return (
    <div className="App">
      <Header />
      <GlobalStyle />
      <Routes>
        <Route path="/:id" element={<Home />} end />
        <Route path="/formularioDeReclamacao" element={<ComplaintForm />} />
        <Route
          path="/formularioEnviadoComSucesso"
          element={<ComplaintSuccessfully />}
        />
        <Route
          path="/consultaDeProtocolo/:protocol"
          element={<Consultation />}
        />
        <Route path="*" element={<Home />} />
      </Routes>
      <AccordionSection />
      {location.pathname === `/${idParams}` && <Merchandising />}
      <Footer />
    </div>
  );
};
