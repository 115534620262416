import React, { createContext, useContext, useState } from 'react';
const ProtocolStateContext = createContext();

export const ProtocolState = ({ children }) => {
  const [protocol, setProtocol] = useState(null);

  return (
    <ProtocolStateContext.Provider value={{ protocol, setProtocol }}>
      {children}
    </ProtocolStateContext.Provider>
  );
};

export const useProtocolState = () => useContext(ProtocolStateContext);
