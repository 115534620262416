import React, { useState } from 'react';
import { ReactComponent as CadeadoChecked } from '../../assets/Icon_cadeado_checked.svg';
import { ReactComponent as Pessoa } from '../../assets/Icone_persona.svg';
import { ReactComponent as IconAcompanhamento } from '../../assets/Icon_acompanhamento.svg';
import { ReactComponent as IconFeedBack } from '../../assets/Icon_Feedback.svg';
import { useParamsState } from '../../Providers/ParamsState';
import * as S from './style';

export const Complaint = () => {
  const [cards] = useState([
    {
      id: 1,
      icon: <CadeadoChecked />,
      title: 'Confidencialidade',
      text: 'Suas denúncias são confidenciais e triadas exclusivamente pelo Martinelli Advogados',
    },
    {
      id: 2,
      icon: <Pessoa />,
      title: 'Não retaliação',
      text: 'Proibimos qualquer tipo de retaliação contra denunciantes e participantes das investigações',
    },
    {
      id: 3,
      icon: <IconAcompanhamento />,
      title: 'Acompanhamento',
      text: 'Após realizar uma denúncia, você poderá acompanhar o andamento através deste portal',
    },
    {
      id: 4,
      icon: <IconFeedBack />,
      title: 'Feedback',
      text: 'A denúncia pode ser procedente ou não, mas você sempre receberá um retorno sobre a mesma.',
    },
  ]);

  const { isLoading, colors } = useParamsState();

  if (!isLoading) {
    return (
      <S.Container >
        <S.TextBox
          color={
            localStorage.getItem('colors')
              ? JSON.parse(localStorage.getItem('colors'))
              : colors
          }
        >
          <h1>
            Por uma organização mais <small>ética</small>
          </h1>
          <p>
            O canal adequado para que qualquer pessoa, interna ou externa,
            reporte suspeitas de corrupção, fraudes e demais previstas na Lei n.
            12.846/2013, atos de assédio, nos termos da Lei 14.457/2022, entre
            outros atos que contrariem nosso compromisso com a ética e a
            integridade. Contamos com você!
          </p>
        </S.TextBox>
        <S.CardsBox
          color={
            localStorage.getItem('colors')
              ? JSON.parse(localStorage.getItem('colors'))
              : colors
          }
        >
          {cards.map((card) => (
            <S.Card
              key={card.id}
              color={
                localStorage.getItem('colors')
                  ? JSON.parse(localStorage.getItem('colors'))
                  : colors
              }
            >
              {card.icon}
              <h3>{card.title}</h3>
              <p>{card.text}</p>
            </S.Card>
          ))}
        </S.CardsBox>
      </S.Container>
    );
  }
};
