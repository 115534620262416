import styled from 'styled-components';

export const Conatiner = styled.section`
  padding: 72px 16px 64px;
  @media only screen and (min-width: 600px) {
    padding: 72px 48px 64px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 72px 80px 64px;
  }
`;
export const Card = styled.div`
  border-radius: 10px;
  background-color: #f6f9fe;
  padding: 16px;
  color: #1a451d;
  display: flex;
  flex-direction: column;
  gap: 50px;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #272d37;
    span {
      color: ${({ color }) => color.primaria};
    }
    margin-bottom: 16px;
    @media only screen and (min-width: 600px) {
      margin-bottom: 32px;
    }
    @media only screen and (min-width: 1024px) {
      margin-bottom: 40px;
    }
  }
  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #5f6d7e;
    margin-bottom: 16px;

    @media only screen and (min-width: 600px) {
      margin-bottom: 32px;
    }
    @media only screen and (min-width: 1024px) {
      margin-bottom: 40px;
    }
  }

  h2 {
    margin-bottom: -70px;
    margin-left: 20px;
  }

  @media only screen and (min-width: 600px) {
    padding: 32px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 64px;
  }
`;

export const CardsMessage = styled.div`
  width: 100%;
`;
export const AreaMessage = styled.div`
  padding: 25px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  span {
    width: fit-content;
  }

  pre {
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    line-height: 1.5;
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

export const StatusCard = styled.div`
  border-radius: 10px;
  background-color: ${({ status, color }) =>
    status ? '#fff' : color.secundaria};
  padding: 16px;
  color: #1a451d;
  display: flex;

  h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 4px;
  }
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.1px;
    color: #000000;
    margin-bottom: 8px;
  }
  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #5f6d7e;
  }

  @media only screen and (min-width: 600px) {
    padding: 32px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 64px;
  }
`;

export const IconBox = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 48px;
  span {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid;
    padding: 10px;
    border-radius: 50%;
    svg {
      path {
        fill: #fff;
      }
    }
  }

  .inProgress {
    border-color: ${({ color }) => color.primaria};
    svg {
      path {
        fill: ${({ color }) => color.primaria};
      }
    }
  }
  .complete {
    background-color: ${({ color }) => color.primaria};
    border-color: ${({ color }) => color.primaria};
    svg {
      path {
        fill: #fff;
      }
    }
  }

  @media only screen and (min-width: 600px) {
    width: 20px;
    height: 20px;
    span {
      width: 20px;
      height: 20px;
    }
  }
  @media only screen and (min-width: 1024px) {
    width: 24px;
    height: 24px;
    span {
      width: 24px;
      height: 24px;
    }
  }
`;

export const FormBox = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;

  button {
    color: #fff;
    background-color: ${({ color }) => color.primaria};
    width: 100%;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
    border-radius: 5px;
    padding: 8px 12px;
    border: 1px solid ${({ color }) => color.primaria};
    height: 40px;
    @media only screen and (min-width: 600px) {
      padding: 12px 18px;
      width: fit-content;
    }
  }
  span {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.1px;
    color: #5f6d7e;
    margin-top: 8px;
    display: block;
  }

  .buttonSubmit,
  .anexoBtn {
    :disabled {
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;
    }
  }

  .nameEmail {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media only screen and (min-width: 600px) {
      flex-direction: row;
      gap: 24px;
    }
    @media only screen and (min-width: 1024px) {
      gap: 32px;
    }
  }
`;

export const FormCard = styled.div`
  border-radius: 8px;
  padding: 16px;

  h3 {
    margin-bottom: 16px;
  }
  p {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.1px;
    color: #49556d;
    margin-top: -16px;
    margin-bottom: 16px;
    span {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.1px;
      font-weight: 600;
      color: #b02817;
      margin: 16px 0;
      display: block;
    }
  }
`;

export const formCardFile = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 600px) {
    gap: 0;
    flex-direction: row;
  }
  @media only screen and (min-width: 1024px) {
    width: 440px;
  }

  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
    background-color: #fff;
    border-radius: 6px 0 0 6px;
    height: 100%;
  }
  button {
    color: #fff;
    background-color: ${({ color }) => color && color.primaria};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
    border: 1px solid ${({ color }) => color && color.primaria};
    height: 100%;
    border-radius: 0 6px 6px 0;
    padding: 12px 18px;
    width: fit-content;
  }

  .css-1785fus-JoyLinearProgress-root::before {
    background-color: ${({ color }) => color && color.primaria};
  }
`;

export const ButtonBox = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  font-size: 15px;
  margin-top: 50px;
  a {
    color: ${({ color }) => color.primaria};
    display: flex;
    align-items: center;
    gap: 6px;
    :visited {
      color: ${({ color }) => color.primaria};
    }
  }
  .bgColor {
    background-color: ${({ color }) => color.primaria};
    color: #fff;
    padding: 12px 18px;
    border-radius: 6px;
    :visited {
      color: #fff;
    }
  }
`;

export const anexoBox = styled(ButtonBox)`
  display: flex;
  gap: 20px;
  align-items: start;
  flex-wrap: wrap;

  a {
    font-weight: bolder;
    letter-spacing: 1px;
    border-radius: 50px;
    padding: 5px 25px;
    max-width: fit-content;
  }
`;

export const ShowMore = styled.button`
  background-color: ${({ color }) => color.primaria};
  color: #fff;
  padding: 12px 18px;
  border-radius: 6px;
  font-size: 16px;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
`;
