import React from 'react';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useParamsState } from '../../Providers/ParamsState';
import * as S from './style';

export const Merchandising = () => {
  const { register, handleSubmit } = useForm();
  const { isLoading } = useParamsState();


  const onSubmit = (data) => {
    console.log('data', data);
  };

  if (!isLoading) {
  return (
    <S.Container>
      <S.TextBox>
        <h1>Quer saber mais sobre a área de integridade e ética corporativa do Martinelli?</h1>
        <p>
        Insira seu e-mail ao lado e nossos especialistas entrarão em contato com você para falar sobre a área.
        </p>
      </S.TextBox>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Insira seu e-mail"
          variant="outlined"
          fullWidth
          size="small"
          {...register('email', {})}
        />
        <button>Buscar</button>
      </S.Form>
    </S.Container>
  );}
};
