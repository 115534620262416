import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  width: 100%;
  a {
    color: #272d37;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    :visited {
      color: #272d37;
    }
  }
`;

export const SubContainer = styled.div`
  box-sizing: border-box;
  /* padding: 16px; */
  padding: 0 16px;
  height: 56px;
  @media only screen and (min-width: 600px) {
    height: 60px;
  }
  @media only screen and (min-width: 1024px) {
    height: 70px;
    /* padding: 20px 112px; */
    padding: 0 112px;
  }
  border-bottom: 1px solid #eaebf0;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logoDefault {
    height: 100%;
    img {
      max-width: 150px;
    }
  }
  .logo {
    height: 100%;
    box-sizing: border-box;
    padding: 8px 0;
    img {
      height: 100%;
      max-width: 150px;
    }
  }
`;

export const MenuBox = styled.nav`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  flex-direction: column;
  position: absolute;
  top: 80px;
  text-align: right;
  padding: 16px;
  margin-right: 16px;
  gap: 10px;
  z-index: 2;

  div {
    display: flex;
    flex-direction: column;
    gap: 10px;

    a {
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 6px;
    }
  }
`;

export const CloseMenu = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
`;

export const MenuBoxDesktop = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 64px;
  div {
    display: flex;
    align-items: center;
    gap: 32px;
  }
  a {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;
