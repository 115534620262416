import React, { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import logo from '../../assets/Denunciaê_logo.svg';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { useParamsState } from '../../Providers/ParamsState';
import * as S from './style';

export const Footer = () => {
  const [idParams, setIdParams] = useState(null);
  const { isLoading, urlDownloads } = useParamsState();

  useEffect(() => {
    setIdParams(localStorage.getItem('idParams'));
  }, [localStorage.getItem('idParams')]);

  if (!isLoading) {
    return (
      <S.Container>
        <h2>Martinelli Advogados</h2>
        <S.TextBox>
          <h3>Entre em contato</h3>
          <p>
            Rua Coronel Santiago, 177 Anita Garibaldi, Joinville-SC |
            CEP:89203-560
          </p>
          <p>CNPJ: 01.650.515/0001-08</p>
          <p className="blueColor">
            <EmailOutlinedIcon />
            martinelli@martinelli.adv.br
          </p>
          <p className="blueColor">
            <PhoneOutlinedIcon />
            (47) 2101-1800
          </p>
        </S.TextBox>
        <S.LinkBox>
          <HashLink to={`/${idParams}#consulta`}>Consultar</HashLink>
          <a href="/formularioDeReclamacao">Denunciar</a>
          {urlDownloads && (
            <HashLink to={`/${idParams}#leiturasImportantes`}>
              Documentos
            </HashLink>
          )}
          <HashLink to="#ajuda">Ajuda</HashLink>
        </S.LinkBox>
        <S.CopyR>© 2023 Martinelli. Todos os Direitos Reservados.</S.CopyR>
      </S.Container>
    );
  }
};
