import React from 'react';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useParamsState } from '../../Providers/ParamsState';
import * as S from './style';

export const Consult = () => {
  const { register, handleSubmit } = useForm();
  const { isLoading, colors } = useParamsState();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    if (data.protocolNumber)
      return navigate(`/consultaDeProtocolo/${data.protocolNumber}`);
  };

  if (!isLoading) {
    return (
      <S.Container id="consulta" >
        <S.FormBox>
          <S.TextBox
            color={
              localStorage.getItem('colors')
                ? JSON.parse(localStorage.getItem('colors'))
                : colors
            }
          >
            <span>ACOMPANHE SUA DENÚNCIA</span>
            <h1>
              Para consultar, busque pelo número de protocolo da denúncia
              realizada:
            </h1>
          </S.TextBox>
          <S.Form
            onSubmit={handleSubmit(onSubmit)}
            color={
              localStorage.getItem('colors')
                ? JSON.parse(localStorage.getItem('colors'))
                : colors
            }
          >
            <TextField
              label="Insira o número do protocolo"
              variant="outlined"
              fullWidth
              size="small"
              {...register('protocolNumber', {})}
            />
            <button>Buscar</button>
          </S.Form>
        </S.FormBox>
      </S.Container>
    );
  }
};
