import React, { useEffect, useState } from 'react';
import { Form } from '../../Components/Form';
import { ImgHeader } from '../../Components/ImgHeader';
import { handleGetData } from '../../Services';
import * as S from './style';
import { useParamsState } from '../../Providers/ParamsState';

export const ComplaintForm = () => {
  const [attentionInformative, setAttentionInformative] = useState(null);
  const [denunciationNewsletter, setDenunciationNewsletter] = useState(null);
  const [idParams, setIdParams] = useState(null);
  const { isLoading } = useParamsState();

  useEffect(() => {
    setIdParams(localStorage.getItem('idParams'));
  }, [localStorage.getItem('idParams')]);

  useEffect(() => {
    handleGetData(`corporacao/${idParams}`)
      .then((response) => {
        setAttentionInformative(
          response?.data?.configuracao?.informativoAtencao,
        );
        setDenunciationNewsletter(
          response?.data?.configuracao?.informativoDenuncia,
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, [idParams]);

  if (!isLoading) {
    return (
      <S.Container>
        <ImgHeader />
        <S.TextBox>
          <h1>Sobre o Canal de Ética</h1>
          <p>{denunciationNewsletter && denunciationNewsletter}</p>
          <p>{attentionInformative && attentionInformative}</p>
        </S.TextBox>
        <Form />
      </S.Container>
    );
  }
};
