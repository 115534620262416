import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  @media only screen and (min-width: 600px) {
    padding: 40px 32px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 64px 80px;
  }
`;

export const TextBox = styled.div`
  padding: 16px 0;
  max-width: 840px;

  @media only screen and (min-width: 1024px) {
    margin: 0 auto;
  }
  h1 {
    font-weight: 600;

    font-size: 24px;
    line-height: 40px;
    letter-spacing: -0.02em;
    margin-bottom: 8px;
    @media only screen and (min-width: 600px) {
      font-size: 32px;
    }
  }

  p,
  li {
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.1px;
    color: #5f6d7e;
  }

  span {
    font-weight: 600;
    color: #b02817;
    font-size: 15px;
  }
  ul {
    padding-left: 24px;
    li {
      list-style-type: disc;
    }
  }
`;
