import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParamsState } from '../../Providers/ParamsState';
import * as S from './style';

export const AccordionSection = () => {
  const { colors } = useParamsState();
  const [accordions] = useState([
    {
      id: 1,
      title: 'Para que serve este canal?',
      text: 'Este canal serve para relatar de forma segura, se deseja, anônima, qualquer ato de colaboradores, fornecedores, prestadores de serviços que viole a legislação, o Código de Conduta, as normas e políticas internas da instituição.',
    },
    {
      id: 2,
      title: 'Quem pode utilizar o canal?',
      text: 'Qualquer pessoa que tenha conhecimento de descumprimento de leis, do Código de Conduta, normas ou políticas internas da instituição.',
    },
    {
      id: 3,
      title: 'O que pode ser relatado no Canal?',
      text: 'O conceito de denúncia abrange temas como suspeita ou certeza de atos de corrupção, assédio, favorecimento de terceiros ou fornecedores, roubo, fraude, furto e diversas outras condutas que violem a lei, o Código de Conduta, normas ou políticas internas da instituição que se relacionem com colaboradores, fornecedores, prestadores de serviços e qualquer pessoa que haja em nome da instituição.',
    },
    {
      id: 4,
      title: 'Quem receberá minha a Denúncia?',
      text: 'A denúncia será recebida por uma equipe de profissionais qualificados do time do Martinelli Advogados e, após triada, será encaminhado ao Comitê responsável pela apuração da denúncia. Todos os envolvidos no processo de recebimento e apuração.',
    },
    {
      id: 5,
      title: 'Preciso apresentar provas? ',
      text: 'Não é indispensável, mas a apresentação ou indicação de elementos que comprovem que o fato relatado ocorreu poderá ser decisiva na apuração e nas medidas a serem tomadas em razão da denúncia.',
    },
  ]);

  return (
    <S.Container id="ajuda">
      <S.TextBox  color={
            localStorage.getItem('colors')
              ? JSON.parse(localStorage.getItem('colors'))
              : colors
          }>
        <h1>
          <span>Dúvidas</span> sobre o portal de denúncias?
        </h1>
        {/* <p>
          Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi.
          Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla,
          mattis ligula consectetur,.
        </p> */}
      </S.TextBox>
      <S.AccordionsBox>
        {accordions.map((item) => (
          <Accordion key={item.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h1>{item.title}</h1>
            </AccordionSummary>
            <AccordionDetails>
              <p>{item.text}</p>
            </AccordionDetails>
          </Accordion>
        ))}
      </S.AccordionsBox>
    </S.Container>
  );
};
